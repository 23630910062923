@font-face {
  font-family: "nunito_bold";
  src: url("../assets/fonts/nunito_bold.ttf") format("truetype");
}

@font-face {
  font-family: "nunito_regular";
  src: url("../assets/fonts/nunito_regular.ttf") format("truetype");
}
.desktopOnly {
  display: block;
}
@media (max-width: 768px) {
  .desktopOnly {
    display: none;
  }
}

.career-navigator-form-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #f8d575;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 18px;
  justify-content: space-between;
  border-radius: 15px;
}

.img-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
  justify-content: center;
}

.img-container img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .img-container2 {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    justify-content: center;
  }
  .img-container2 img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .img-container2 {
    display: none;
  }
}
.vertical-line {
  border-left: 1px solid black; /* Make the line black */
  margin-right: 22px;
}

.career-navigator-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.career-navigator-form-title {
  font-family: "nunito_bold";
  font-size: 24px;
  margin-bottom: 10px;
  color: #094f98;
  text-align: center;
}

.career-navigator-form-subtitle {
  font-family: "nunito_regular";
  font-size: 22px;
  color: #094f98;
  margin-bottom: 10px;
  text-align: center;
}
.form-text-field {
  font-family: "nunito_regular";
  font-size: 22px;
  color: #094f98;
}

.career-navigator-button {
  font-family: "nunito_bold";
  font-size: 22px;
  color: #fff;
  background-color: #094f98;
  border: 1px solid #094f98;
  border-radius: 15px;
  padding: 10px;
  margin-top: 12px;
  cursor: pointer;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}
