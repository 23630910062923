@font-face {
  font-family: "nunito_bold";
  src: url("../assets/fonts/nunito_bold.ttf") format("truetype");
}

@font-face {
  font-family: "nunito_regular";
  src: url("../assets/fonts/nunito_regular.ttf") format("truetype");
}

.menu-container {
  display: flex;
  padding-top: 35%;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  overflow-x: hidden;
}

.menu-item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
}

.menu-item {
  font-family: "nunito_regular";
  font-size: 20px;
  color: #fff;
  flex: 1;
  text-align: left;
}

.menu-item:hover {
  font-family: "nunito_bold";
}
