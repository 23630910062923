body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  position: relative;
  background: rgb(9, 79, 152);
  background: linear-gradient(90deg, rgba(9, 79, 152, 1) 0%, rgba(58, 148, 237, 1) 35%, rgba(9, 79, 152, 1) 100%);
}

.detail-container {
  padding: 18px;
}

.dialog-logo {
  width: 200px;
  align-self: center;
}

.detail-title-card-container {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 7vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.body::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15); /* Black with 50% opacity */
}

.detail-mobile-view-title-card-container {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.detail-left-line-mobile-view {
  flex: 1;
  height: 1px;
  border-bottom: 2px solid #fff;
}

.detail-title-mobile-view {
  border: 2px solid #fff;
  flex: 3;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  font-family: "nunito_regular";
  font-size: 18px;
  color: #fff;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: center;
  align-items: center;
}

.detail-right-line-mobile-view {
  flex: 1;
  height: 1px;
  border-bottom: 2px solid #fff;
}

.detail-left-line {
  flex: 0.7;
  height: 1px;
  border-bottom: 2px solid #fff;
  margin-left: 27%;
}
.detail-title {
  border: 2px solid #fff;
  flex: 2;
  padding: 6px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  font-family: "nunito_regular";
  font-size: 22px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-right-line {
  flex: 0.7;
  height: 1px;
  border-bottom: 2px solid #fff;
  margin-right: 27%;
}

.detail-description {
  font-family: "nunito_regular";
  font-size: 24px;
  margin-top: 32px;
  color: #fff;
  text-align: left;
}

.detail-bullets {
  background-color: #f8d575;
  font-family: "nunito_regular";
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 22px;
  padding: 12px;
  flex: 1;
  color: #000;
  text-align: left;
}

.detail-bullets {
  font-family: "nunito_regular";
  font-size: 22px;
  padding: 12px;
  flex: 1;
  color: #000;
  text-align: left;
}

.career-center-locations {
  font-family: "nunito_regular";
  font-size: 22px;
  flex: 1;
  color: #fff;
  text-align: left;
}

.career-center-location-container {
  margin-top: 18px;
  display: flex;
  flex: 1;
  margin-left: 22px;
  width: 80%;
  flex-direction: row;
}

.bullet-container {
  margin-top: 18px;
  display: flex;
  flex: 1;
  margin-left: 22px;
  width: 80%;
  flex-direction: row;
}

.career-center-location-bullet-container {
  width: 10vh;
  padding-top: 12px;
  padding-left: 12px;
  color: #fff;
  display: flex;
  text-align: center;
  font-family: "nunito_bold";
  font-size: 24px;
}

.index-container {
  width: 10vh;
  background-color: #094f98;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "nunito_bold";
  font-size: 24px;
}

.logo-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.submit-phonenumber-title {
  font-family: "nunito_regular";
  font-size: 26px;
  color: #094f98;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 18px;
}

.mobilenumber-submit-button {
  font-family: "nunito_bold";
  font-size: 22px;
  color: #fff;
  background-color: #094f98;
  border: 1px solid #094f98;
  border-radius: 15px;
  padding: 10px;
  margin-top: 12px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.detail-career-center-title {
  font-family: "nunito_regular";
  font-size: 24px;
  color: #fff;
  text-align: start;
  margin: 18px;
}

@media (max-width: 768px) {
  .bullet-container {
    width: 100%;
    margin-left: 0;
  }
  .submit-phonenumber-title {
    font-size: 22px;
  }
  .detail-career-center-title {
    font-size: 20px;
  }
  .career-center-locations {
    font-size: 18px;
  }
  .detail-description {
    font-size: 20px;
  }
  .detail-bullets {
    font-size: 18px;
  }
}
