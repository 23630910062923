@font-face {
  font-family: "nunito_bold";
  src: url("../assets/fonts/nunito_bold.ttf") format("truetype");
}

@font-face {
  font-family: "nunito_regular";
  src: url("../assets/fonts/nunito_regular.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  background-color: #094f98;
  font-size: 1rem !important;
}

.section-header {
  margin: 0;
  padding: 1rem;
  display: flex;
  flex: 1;
  position: sticky;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
  top: 0;
  z-index: 1;
}

.logo {
  width: 15%;
  align-self: center;
}
.career-navigator-img {
  width: 18%;
}

.send-me-a-text-img {
  width: 18%;
}

.good-will-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #094f98;
  justify-content: space-evenly;
  margin-right: 12px;
}

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50vh;
  width: 80%;
  max-width: 80%;
  background-color: #f8d575;
  border-radius: 15px;
  margin-top: 22px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  align-self: center;
  margin-right: 10px;
}

.card img {
  height: 60%;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
}

.card-subtitle {
  text-align: center;
  width: 100%;
  font-size: 18px;
  margin-bottom: 12px;
  font-family: "nunito_regular";
}

.card-title {
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-family: "nunito_regular";
}

.card:hover .card-title {
  font-family: "nunito_bold";
}

@media (max-width: 768px) {
  .card {
    flex: 1 0 100%;
    max-width: 80%;
  }
  .logo {
    width: 35%;
  }
  .send-me-a-text-img {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 0 100%;
    max-width: 80%;
  }

  .logo {
    width: 35%;
  }
  .career-navigator-img {
    width: 70%;
  }

  .send-me-a-text-img {
    width: 70%;
  }
}

.container-with-center-alignment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: -moz-calc(100vh - (36px + 56px));
  height: -webkit-calc(100vh - (36px + 56px));
  height: calc(100vh - (36px + 56px));
  text-align: center;
  font-family: "nunito_regular";
  font-size: x-large;
  color: #fff;
}

.career-navigator-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.loading-text {
  font-family: "nunito_regular";
  font-size: 24px;
  color: #fff;
}

.error-img {
  width: 300px;
  object-fit: cover;
}

.error-text {
  font-family: "nunito_regular";
  font-size: 22px;
  color: #fff;
  margin-top: -50px;
}

.loading-img {
  width: 25%;
}
.carousel-subtitle {
  font-family: "nunito_regular";
  font-size: 28px;
  color: #fff;
  align-self: center;
}

.carousel-title {
  font-family: "nunito_regular";
  font-size: 36px;
  color: #fff;
  align-self: center;
}
