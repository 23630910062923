@font-face {
  font-family: "nunito_bold";
  src: url("../assets/fonts/nunito_bold.ttf") format("truetype");
}

@font-face {
  font-family: "nunito_regular";
  src: url("../assets/fonts/nunito_regular.ttf") format("truetype");
}

body {
  overflow-x: hidden;
  height: auto;
}
.container {
  display: flex;
  width: 100%;
  height: 110vh;
  flex-direction: column;
}

.title-card-container {
  display: flex;
  margin-top: 7vh;
  margin-bottom: 5vh;
  height: 7vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.left-line {
  flex: 0.7;
  height: 1px;
  border-bottom: 2px solid #fff;
  margin-left: 27%;
}
.title {
  border: 2px solid #fff;
  flex: 2;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  font-family: "nunito_regular";
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-line {
  flex: 0.7;
  height: 1px;
  border-bottom: 2px solid #fff;
  margin-right: 27%;
}

.subcategory-card {
  flex: 0 0 auto; /* Add this line */
  flex-direction: column;
  height: 43vh;
  max-height: 43vh;
  background-color: #3a94ed;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 10px;
  border-radius: 7px;
}

.subcategory-card:hover {
  background-color: #fff;
}

.subcategory-card:hover .subcategory-card-title {
  color: #3a94ed;
}

.subcategory-card:hover .subcategory-card-subtitle {
  color: #3a94ed;
}

.subcategory-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.subcategory-card-container > div {
  flex: 0 0 calc(33.3333% - 10px);
  margin: 5px;
}
.subcategory-card-img {
  height: 28%;
  width: 28%;
  margin-top: 14px;
  margin-bottom: 8px;
  object-fit: contain;
}
.subcategory-card-title {
  font-family: "nunito_bold";
  font-size: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
.subcategory-card-subtitle {
  font-family: "nunito_regular";
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.hamburger-menu {
  font-size: 24px;
  margin-right: 10px;
}

.breadcrumb-container {
  height: 32px;
  width: 100%;
  padding-left: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.breadcrumb-text {
  font-family: "nunito_regular";
  font-size: 18px;
  color: #fff;
  text-align: left;
  align-self: center;
}
.breadcrumb-text-link {
  font-family: "nunito_regular";
  font-size: 18px;
  color: #fff;
  text-align: left;
  align-self: center;
  cursor: pointer;
}
.breadcrumb-text-link :hover {
  font-weight: bold;
}

@media (max-width: 1024px) {
  .subcategory-card-container {
    padding-left: 2%;
    padding-right: 2%;
  }
  .subcategory-card {
    height: 48vh;
    max-height: 48vh;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 35%;
  }
  .subcategory-card-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 35%;
  }
  .subcategory-card-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.mobile-view-card {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #3a94ed;
  flex-direction: row;
  width: 95%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: height 1s ease-in-out;
  height: auto;
  overflow: hidden;
}

.mobile-view-card.expanded {
  height: auto;
}
.mobile-view-card-container {
  padding: 18px;
  background-color: #fff;
}

.mobile-view-left-line {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid #094f98;
}
.mobile-view-title {
  border: 1px solid #094f98;
  flex: 3;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  font-family: "nunito_regular";
  font-size: 18px;
  color: #094f98;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: center;
  align-items: center;
}

.mobile-view-right-line {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid #094f98;
}

.mobile-view-title-card-container {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.mobile-view-img {
  height: 50px;
  width: 50px;
  margin-right: 12px;
  object-fit: contain;
}

.mobile-view-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.mobile-view-title-text {
  font-family: "nunito_bold";
  font-size: 20px;
  color: #fff;
  text-align: left;
}

.chevron-icon {
  color: #fff;
  cursor: pointer;
}
.mobile-view-card:hover {
  background-color: #fff;
  height: auto;
}

.mobile-view-card:hover .mobile-view-title-text {
  color: #3a94ed;
}

.mobile-view-subtitle-text {
  font-family: "nunito_regular";
  text-align: left;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  color: #3a94ed;
  max-height: 0;
}

.mobile-view-card:hover .mobile-view-subtitle-text {
  opacity: 1;
  max-height: 100px;
}
.mobile-view-card:hover .chevron-icon {
  color: #3a94ed;
}
